import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/seo";

const SuperbaseNGPersonal = () => (
  <Layout>
    <Seo
      title="Superbase NG Personal"
      description="Buy Superbase NG Personal - Create Forms, Databases and Reports Quickly"
    />
    <div className="container">
      <main>
        <div className="pricing-header p-3 pb-md-4 text-center">
          <h1 className="display-3">Superbase NG Personal</h1>
          <div className="col-lg-6 mx-auto">
            <p className="fs-5 text-muted">
              Create Forms, Databases and Reports all in one simple GUI
              interface.
            </p>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 mb-3 text-center py-3">
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">Personal Free</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  £0<small className="text-muted fw-light"></small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Create and Modify Databases</li>
                  <li>Easy to Use GUI Form designer</li>
                  <li>Quick and Easy reporting</li>
                  <li>Help center access</li>
                </ul>
                <a
                  className="w-100 btn btn-lg btn-outline-primary"
                  href="https://www.superbase.com/products/superbase-ng-free/"
                >
                  Sign up for free
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">Personal Plus</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  £10<small className="text-muted fw-light">/year</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Create and Modify Databases</li>
                  <li>Advanced GUI Form designer</li>
                  <li>Common functions right from the GUI</li>
                  <li>Priority email support</li>
                </ul>
                <a
                  type="button"
                  className="w-100 btn btn-lg btn-primary"
                  href="https://buy.stripe.com/test_eVa7sL4rG35h69a9AB"
                >
                  Get started
                </a>
              </div>
            </div>
          </div>
        </div>

        <h2 className="display-6 text-center mb-4">Compare plans</h2>

        <div className="container table-responsive">
          <table className="table text-center">
            <thead>
              <tr>
                <th width="34%"> </th>
                <th width="33%">Personal</th>
                <th width="33%">Personal Plus</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" className="text-start">
                  Personal Use *
                </th>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Commercial Use
                </th>
                <td></td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
              </tr>
            </tbody>

            <tbody>
              <tr>
                <th scope="row" className="text-start">
                  Create and Modify Databases
                </th>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Design tools for display forms, reports, and labels
                </th>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Built-in Quick Reporting and SQL Record Filtering
                </th>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Send Records and Reports directly **
                </th>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Sharing **
                </th>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Common Functions right from the GUI
                </th>
                <td></td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Extra security **
                </th>
                <td></td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            <small>
              * Subject to our{" "}
              <a href="https://www.superbase.com/non-commercial/">
                Non-commercial license
              </a>
            </small>
          </p>
          <p>
            <small>** Coming soon...</small>
          </p>
        </div>
        <div className="container py-5" id="gallery">
          <h2 className="pb-2 border-bottom">Gallery</h2>
          <div className="row">
            <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
              <img
                src="https://www.superbase.com/wp-content/uploads/2020/05/image-1.png"
                className="w-100 shadow-1-strong rounded mb-4"
                alt=""
              />
              <div className="caption">
                <p>Superbase NG Personal </p>
              </div>

              <img
                src="https://www.superbase.com/wp-content/uploads/2020/05/image-2.png"
                className="w-100 shadow-1-strong rounded mb-4"
                alt=""
              />
              <div className="caption">
                <p>Database Table Designer</p>
              </div>
            </div>

            <div className="col-lg-4 mb-4 mb-lg-0">
              <img
                src="https://www.superbase.com/wp-content/uploads/2020/05/image-3.png"
                className="w-100 shadow-1-strong rounded mb-4"
                alt=""
              />
              <div className="caption">
                <p>Record View</p>
              </div>

              <img
                src="https://www.superbase.com/wp-content/uploads/2020/05/image-4-1024x650.png"
                className="w-100 shadow-1-strong rounded mb-4"
                alt=""
              />
              <div className="caption">
                <p>Form Designer</p>
              </div>
            </div>

            <div className="col-lg-4 mb-4 mb-lg-0">
              <img
                src="https://www.superbase.com/wp-content/uploads/2015/12/Table-View-768x545.png"
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Table View"
              />
              <div className="caption">
                <p>Table View</p>
              </div>

              <img
                src="https://www.superbase.com/wp-content/uploads/2015/12/Quick-Report-Designer-768x475.png"
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Quick Report Designer Screenshot"
              />
              <div className="caption">
                <p>Quick Report Designer</p>
              </div>
            </div>
          </div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" display="none">
          <symbol id="check" viewBox="0 0 16 16">
            <title>Check</title>
            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"></path>
          </symbol>
        </svg>
      </main>
    </div>
  </Layout>
);

export default SuperbaseNGPersonal;
